export const videoContent = [
  'Animation',
  'Instagram Reel',
  'TV Marketing',
  'TikTok Feed',
  'TikTok Top View',
  'TikTok Testimonial',
  'TikTok Stop Motion',
  'TikTok Animation',
  'TikTok Narrative',
  'TikTok Tutorial',
  'YouTube Testimonial/Review',
  'YouTube Stop Motion',
  'YouTube Animation',
  'YouTube Narrative',
  'YouTube Tutorial',
  'Stop Motion Video',
]
// Both images and videos
export const mediaContent = [
  'E-Commerce Marketing',
  'Email Marketing',
  'Facebook News Feed',
  'Facebook Story',
  'Instagram Post',
  'Instagram Story',
  'Pinterest',
  'Professional Video',
  'Snapchat',
]
export const storyPlacement = ['Facebook Story', 'Instagram Story']
export const postPlacement = [
  'Instagram Post',
  'TikTok Feed',
  'TikTok Top View',
  'TikTok Testimonial',
  'TikTok Stop Motion',
  'TikTok Animation',
  'TikTok Narrative',
  'YouTube Testimonial/Review',
  'YouTube Stop Motion',
  'YouTube Animation',
  'YouTube Narrative',
  'Snapchat',
  'Pinterest',
]
export const feedPlacement = ['Facebook News Feed']
export const tutorialPlacement = ['TikTok Tutorial', 'YouTube Tutorial']
export const reelPlacement = ['Instagram Reel']
export const otherPlacement = [
  'Stop Motion Video',
  'Animation',
  'Email Marketing',
  'TV Marketing',
  'E-Commerce Marketing',
  'Professional Video',
]

export const contentTypes = [...mediaContent, ...videoContent].sort()
