import ageRanges from 'account/data/age-ranges'
import deliveryTimes from 'gig/data/delivery-times.json'
import { mainPlatforms, otherPlatforms } from 'gig/data/platforms'
import {
  mediaContent,
  videoContent,
  storyPlacement,
  postPlacement,
  feedPlacement,
  tutorialPlacement,
  reelPlacement,
  otherPlacement,
} from 'gig/data/content-types'
import type { CategoryFilter } from 'gig/types/category-filter'

export const categoryFilters: CategoryFilter[] = [
  {
    id: 'variants.attributes.extra_platforms',
    name: 'Platform',
    activeFilterName: '',
    options: [],
    mapping: {
      ...Object.fromEntries(
        mainPlatforms.map((platform) => [platform, [platform]]),
      ),
      Other: otherPlatforms,
    },
    splitActiveOptions: true,
    twoColumns: true,
  },
  {
    id: 'variants.attributes.follower_count_numeric',
    name: 'Follower count',
    options: [],
    mapping: {
      '1k+': 1000,
      '10k+': 10000,
      '20k+': 20000,
      '50k+': 50000,
      '200k+': 200000,
      '500k+': 500000,
    },
    isNumericRange: true,
    twoColumns: true,
  },
  {
    id: 'variants.attributes.content_type',
    name: 'Placement',
    activeFilterName: 'Content Type',
    options: [],
    mapping: {
      Story: storyPlacement,
      Post: postPlacement,
      Feed: feedPlacement,
      Tutorial: tutorialPlacement,
      Reel: reelPlacement,
      Other: otherPlacement,
    },
    twoColumns: true,
  },
  {
    id: 'variants.attributes.content_type',
    name: 'Content Type',
    activeFilterName: 'Content Type',
    options: [],
    mapping: {
      Video: [...mediaContent, ...videoContent],
      Image: [...mediaContent],
    },
  },
  {
    id: 'variants.attributes.tags',
    name: 'Category / Interests',
    options: [
      {
        text: 'Category / Interests',
        type: 'text',
        placeholder: 'Search',
        id: 'tags-search',
        icon: 'pi-search',
      },
    ],
    autocomplete: true,
    additionalLabel: '(Max 5)',
  },
  {
    id: 'variants.attributes.creator_age',
    name: 'Age',
    options: [],
    mapping: Object.keys(ageRanges)
      .slice(1)
      .map((key) => key),
    splitActiveOptions: true,
    twoColumns: true,
  },
  {
    id: 'variants.attributes.creator_gender',
    name: 'Gender',
    options: [],
    mapping: ['male', 'female', 'other'],
    splitActiveOptions: true,
  },
  {
    id: 'variants.attributes.location',
    name: 'Location',
    activeFilterName: 'Country',
    options: [
      {
        type: 'text',
        text: 'Country',
        placeholder: 'Search Country',
        id: 'country-search',
        icon: 'pi-search',
      },
    ],
    autocomplete: true,
  },
  {
    id: 'variants.attributes.location_state',
    name: 'Location',
    activeFilterName: 'State',
    options: [
      {
        type: 'text',
        text: 'State',
        placeholder: 'Search State',
        id: 'state-search',
        icon: 'pi-search',
      },
    ],
    autocomplete: true,
  },
  {
    id: 'variants.attributes.location_city',
    activeFilterName: 'City',
    name: 'Location',
    options: [
      {
        type: 'text',
        text: 'City',
        placeholder: 'Search City',
        id: 'city-search',
        icon: 'pi-search',
      },
    ],
    autocomplete: true,
  },
  {
    id: 'reviewRatingStatistics.averageRating',
    name: 'Creator Rating',
    options: [],
    isNumericRange: true,
    mapping: {
      '5+': 5,
      '4+': 4,
      '3+': 3,
      '2+': 2,
      '1+': 1,
    },
    oneColumnOnMobile: true,
  },
  {
    id: 'variants.attributes.delivery_time',
    name: 'Delivery Time',
    options: [],
    mapping: {
      'Express 24H': deliveryTimes.slice(0, 1),
      'Up to 3 days': deliveryTimes.slice(0, 3),
      'Up to 7 days': deliveryTimes.slice(0, 7),
    },
  },
  {
    id: 'variants.attributes.licensing_rights',
    name: 'Licensing Rights',
    options: [],
    optionIdToIgnore: 'None',
  },
  {
    id: 'variants.price.centAmount:range',
    name: 'Budget',
    isNumericRange: true,
    options: [
      {
        type: 'number',
        text: 'Min',
        placeholder: '$50',
        id: 'min',
        minValue: 0,
        mode: 'currency',
        currency: 'USD',
        locale: 'en-US',
      },
      {
        type: 'number',
        text: 'Max',
        placeholder: '$100',
        id: 'max',
        minValue: 0,
        mode: 'currency',
        currency: 'USD',
        locale: 'en-US',
      },
    ],
  },
]
